import React, { useCallback } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
import { useBoolean, useInput } from 'react-hanger'
import InputMask from 'react-input-mask'

import * as styles from './styles.module.scss'
import clsx from 'clsx'
import { FiUser, FiMail, FiPhone, FiSend } from 'react-icons/fi'
import { CgSpinner } from 'react-icons/cg'

const contactFormUrl = `https://api.roberty.app/prod/1/website/createBitrixLead`

export const Contact: React.FC = () => {
  const name = useInput(``)
  const phone = useInput(``)
  const email = useInput(``)
  const message = useInput(``)
  const loading = useBoolean(false)

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        const formTitle = `[HighJump - Contato] - ${name.value}`

        await axios
          .post(contactFormUrl, {
            title: formTitle,
            name: name.value,
            phone: phone.value,
            email: email.value,
            description: message.value,
          })
          .then(() => {
            name.clear()
            phone.clear()
            email.clear()
            message.clear()
            toast.success(
              `Sua mensagem foi enviada com sucesso!
							Aguarde, em breve entraremos em contato com você.`
            )
          })
      } catch (error) {
        console.error(error)
        toast.error(
          'Houve um erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.'
        )
      } finally {
        loading.setFalse()
      }
    },
    [name.value, phone.value, email.value, message.value]
  )

  return (
    <div className={styles.formContent}>
      <div className={styles.title}>
        <h3>
          Pronto para
          <br /> o grande <span>salto?</span>
          <br /> Vamos conversar.
        </h3>
      </div>
      <div className={styles.form}>
        <form
          id="contatoForm"
          onSubmitCapture={handleSubmit}
          autoComplete="off"
          role="presentation"
        >
          <div className={clsx(styles.inputGroup)}>
            <span>
              <FiUser />
            </span>
            <input
              name="name"
              type="text"
              placeholder="Seu nome"
              required={true}
              value={name.value}
              onChange={name.onChange}
              disabled={loading.value}
            />
          </div>

          <div className={clsx(styles.inputGroup)}>
            <span>
              <FiPhone />
            </span>
            <InputMask
              mask="99 99999-9999"
              name="phone"
              type="tel"
              placeholder="Telefone (com WhatsApp)"
              required={true}
              value={phone.value}
              onChange={phone.onChange}
              disabled={loading.value}
            />
          </div>

          <div className={clsx(styles.inputGroup)}>
            <span>
              <FiMail />
            </span>
            <input
              name="email"
              type="email"
              placeholder="Seu melhor e-mail"
              value={email.value}
              onChange={email.onChange}
              disabled={loading.value}
            />
          </div>

          <div className={clsx(styles.inputGroup)}>
            <textarea
              name="message"
              placeholder="Como podemos ajudar?"
              required={true}
              value={message.value}
              onChange={message.onChange}
              disabled={loading.value}
            />
          </div>

          <button type="submit" disabled={loading.value}>
            {loading.value ? (
              <>
                Enviando
                <CgSpinner className="icon-spin" />
              </>
            ) : (
              <>
                Enviar
                <FiSend />
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  )
}
