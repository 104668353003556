import React, { useRef, useState } from 'react'
import clsx from 'clsx'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as styles from './styles.module.scss'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { defaultSuccessCases } from '../../utils'

import ctg from '../../images/cases/ctg.png'
import unimed from '../../images/cases/unimed.png'
import okubo from '../../images/cases/okubo.png'

const cases = [
  {
    title: `CTG Brasil`,
    city: `Sāo Paulo, Brasil`,
    quantity: 62,
    image: ctg,
  },
  {
    title: `UNIMED Rio Preto`,
    city: `Sāo Jose do Rio Preto, Brasil`,
    quantity: 96,
    image: unimed,
  },
  {
    title: `Okubo`,
    city: `Ribeirāo Preto, Brasil`,
    quantity: 40,
    image: okubo,
  },
]

export const SliderCases: React.FC = () => {
  const sliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState({
    slideIndex: 1,
  })

  return (
    <div className={styles.sliderContent}>
      <div className={styles.arrows}>
        <span
          onClick={() => {
            sliderRef.current?.slickPrev()
          }}
          className={currentSlide.slideIndex === 1 ? styles.disabled : ''}
        >
          <FiChevronLeft />
        </span>
        <div className={styles.count}>
          {currentSlide.slideIndex}/{cases.length}
        </div>
        <span
          onClick={() => {
            sliderRef.current?.slickNext()
          }}
          className={
            currentSlide.slideIndex === cases.length ? styles.disabled : ''
          }
        >
          <FiChevronRight />
        </span>
      </div>
      <Slider
        ref={sliderRef}
        className={styles.listClients}
        adaptiveHeight={false}
        infinite={false}
        autoplay={false}
        slidesToScroll={1}
        dots={false}
        draggable={true}
        speed={300}
        arrows={false}
        variableWidth={true}
        beforeChange={(current, next) =>
          setCurrentSlide((prevState) => ({
            ...prevState,
            slideIndex: next + 1,
          }))
        }
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              arrows: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              arrows: false,
              adaptiveHeight: true,
              variableWidth: false,
            },
          },
        ]}
      >
        {cases.map((client, index) => (
          <div className={styles.client} key={index}>
            <div
              className={clsx(styles.box, { [styles.line]: index === 0 })}
              style={{ backgroundImage: `url(${client.image})` }}
            >
              <div className={styles.count}>
                <h1>
                  {client.quantity}
                  <small>melhorias</small>
                </h1>
              </div>
              <div className={styles.info}>
                <h3>
                  {client.title}
                  <small>{client.city}</small>
                </h3>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
