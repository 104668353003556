import clsx from 'clsx'
import React, { forwardRef, useEffect, useRef } from 'react'

import * as styles from './styles.module.scss'

interface OutsideLinesProps {
  addTop?: number
  ref: React.RefObject<HTMLDivElement> | any
  type: `diagonal` | `horizontal` | `manifest`
}

export const OutsideLines: React.FC<OutsideLinesProps> = forwardRef(
  ({ addTop, type }, ref: React.RefObject<HTMLDivElement> | any) => {
    const refLine = useRef<HTMLDivElement>(null)

    if (!ref) return null

    const setTop = () => {
      const offset = ref?.current?.offsetTop || 0
      const height = ref?.current?.clientHeight || 0

      const { top: boundsTop, height: boundsHeight } =
        ref?.current?.getBoundingClientRect()

      const top = offset + height + (addTop || 0)
      const horizontal = boundsTop + boundsHeight / 2 - (addTop || 0)
      const manifest = boundsTop

      window.removeEventListener(`scroll`, onScroll)

      if (type === `manifest`) {
        return `${manifest}px`
      }

      if (type === `horizontal`) {
        return `${horizontal}px`
      }

      return `${top}px`
    }

    const onScroll = () => {
      if (ref.current !== null) {
        if (refLine.current !== null) {
          refLine.current.style.top = setTop()
          refLine.current.style.opacity = '1'
        }
      }
    }

    useEffect(() => {
      setTimeout(() => {
        window.addEventListener(`scroll`, onScroll)
      }, 500)

      return () => {
        window.removeEventListener(`scroll`, onScroll)
      }
    }, [])

    return (
      <div
        className={clsx({
          [styles.lines]: type === `diagonal`,
          [styles.horizontal]: type === `horizontal`,
          [styles.manifest]: type === `manifest`,
        })}
        ref={refLine}
      ></div>
    )
  }
)

OutsideLines.defaultProps = {
  addTop: 0,
}
